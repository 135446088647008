import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Panel, Button } from '@cognitranlimited/itis-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setModel } from '../../../../redux/slices/attachments';
import './ChangeModel.scss';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../routes';
export var ChangeModel = function () {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var model = useAppSelector(function (state) { return state.attachments; }).model;
    var clearMachine = function () {
        sessionStorage.removeItem('currentModel');
        sessionStorage.removeItem('currentFamilyName');
        dispatch(setModel(''));
        navigate(routes.home);
    };
    return (_jsx("div", { className: "Screen WelcomeScreen WelcomeScreen-twoColumns  Screen-noHeader ChangeModel", children: _jsx("div", { className: "WelcomeScreen-Content", children: _jsx("div", { className: "PanelGrid WelcomeScreen-panelGrid", children: _jsx("div", { className: "Overlay-Wrapper", children: _jsxs(Panel, { className: "ChangeModel-Panel", children: [_jsx("h4", { className: "ChangeModel-Title", children: "Current machine:" }), _jsx(Panel.Header, { children: _jsx("h3", { children: model }) }), _jsx(Panel.Content, { children: _jsxs("div", { className: "ChangeModel-Buttons", children: [_jsx(Button, { onClick: clearMachine, children: "change machine" }), _jsxs("a", { className: "ButtonLink", target: "_blank", href: "/", children: ["additional machine", _jsx(FontAwesomeIcon, { className: "ChangeModel-LinkIcon", icon: faArrowUpRightFromSquare })] })] }) })] }) }) }) }) }));
};

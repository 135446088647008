import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logOut } from '../../redux/globalActions';
import { useLogin } from '../../auth/useLogin';
import { MenuList } from '../MenuList';
export var SidebarContentUser = function (_a) {
    var onClick = _a.onClick;
    var dispatch = useAppDispatch();
    var user = useAppSelector(function (state) { return state.user; }).user;
    var logIn = useLogin().logIn;
    if (user) {
        return (_jsxs("div", { className: "SidebarSection SidebarUserSection", children: [_jsxs("p", { className: "SidebarUserInfo", children: ["Logged as: ", user === null || user === void 0 ? void 0 : user.firstName, " ", user === null || user === void 0 ? void 0 : user.lastName] }), _jsx("button", { onClick: function () {
                        dispatch(logOut());
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('refreshToken');
                    }, className: "Sidebar_LogoutButton", children: "log out" })] }));
    }
    return (_jsx("div", { className: "SidebarSection", children: _jsx(MenuList, { onClick: onClick, menuItems: [
                {
                    label: 'Login',
                    onClick: function () {
                        logIn();
                    },
                },
            ] }) }));
};

import { jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../redux/hooks';
import { Typography } from '@mui/material';
export var useFields = function (attachment) {
    var currency = useAppSelector(function (state) { return state.attachments.currency; });
    var unit = useAppSelector(function (state) { return state.attachments.unit; });
    var isLoggedIn = useAppSelector(function (state) { return state.user; }).isLoggedIn;
    var getCurrency = function (currency) {
        return currency === 'USD'
            ? (attachment === null || attachment === void 0 ? void 0 : attachment.price.USD.price) + ' (' + currency.toLowerCase() + ')'
            : (attachment === null || attachment === void 0 ? void 0 : attachment.price.CAD.price) + ' (' + currency.toLowerCase() + ')';
    };
    var displayField = function (field) {
        var value = field.value, label = field.label, _a = field.isVisible, isVisible = _a === void 0 ? true : _a;
        if (!isVisible || !attachment || !value) {
            return null;
        }
        var displayValue = typeof value === 'object' && value !== null
            ? "".concat(value.value).concat(value.unit ? " (".concat(value.unit.toLowerCase(), ")") : '')
            : value;
        return (_jsxs(Typography, { variant: "body2", color: "text.secondary", children: [_jsxs("strong", { children: [label, ": "] }), displayValue] }, label));
    };
    var getFields = function () {
        if (!attachment)
            return [];
        return [
            {
                label: 'Part Number',
                value: attachment.partNumber,
            },
            {
                label: 'Sales Code',
                value: attachment.salesCode,
            },
            {
                label: 'Family',
                value: attachment.family,
            },
            {
                label: 'Category',
                value: attachment.category,
            },
            {
                label: 'Description',
                value: attachment.description,
            },
            {
                label: 'Fitment Notes',
                value: attachment.fitmentNotes,
            },
            {
                label: 'List-Price',
                value: getCurrency(currency),
                isVisible: isLoggedIn,
            },
            {
                label: 'Weight',
                value: unit === 'metric'
                    ? attachment.weightKilograms
                    : attachment.weightPounds,
            },
            {
                label: 'Dimension',
                value: attachment.dimension,
            },
            {
                label: 'Width',
                value: unit === 'metric'
                    ? attachment.widthMillimeters
                    : attachment.widthInches,
            },
            {
                label: 'Size',
                value: unit === 'metric'
                    ? attachment.sizeMillimeters
                    : attachment.sizeInches,
            },
            {
                label: 'Pick Up',
                value: attachment.pickUp,
            },
            {
                label: 'Flow Demand',
                value: attachment.flowDemand,
            },
            {
                label: 'Min Operating Pressure',
                value: unit === 'metric'
                    ? attachment.minOperatingPressureBar
                    : attachment.minOperatingPressurePSI,
            },
            {
                label: 'Max Operating Pressure',
                value: unit === 'metric'
                    ? attachment.maxOperatingPressureBar
                    : attachment.maxOperatingPressurePSI,
            },
            {
                label: 'Min Flow Rate',
                value: unit === 'metric'
                    ? attachment.minFlowRateLMin
                    : attachment.minFlowRateGPM,
            },
            {
                label: 'Max Flow Rate',
                value: unit === 'metric'
                    ? attachment.maxFlowRateLMin
                    : attachment.maxFlowRateGPM,
            },
            {
                label: 'Torque',
                value: unit === 'metric' ? attachment.torqueNm : attachment.torqueFTLB,
            },
            {
                label: 'Pin Diameter',
                value: unit === 'metric'
                    ? attachment.pinDiameterMM
                    : attachment.pinDiameterInches,
            },
            {
                label: 'Operating Width',
                value: unit === 'metric'
                    ? attachment.operatingWidthMM
                    : attachment.operatingWidthInches,
            },
            {
                label: 'Volume Capacity',
                value: unit === 'metric'
                    ? attachment.volumeCapacityM3
                    : attachment.volumeCapacityYd3,
            },
            {
                label: 'Load Capacity',
                value: unit === 'metric'
                    ? attachment.loadCapacityKilograms
                    : attachment.loadCapacityPounds,
            },
            {
                label: 'Multifunctional Controls Required',
                value: attachment.multifunctionalControlsRequiredString,
            },
            {
                label: 'Models',
                value: attachment.models.join(', '),
            },
        ];
    };
    var fieldItems = getFields().map(function (field) { return displayField(field); });
    return {
        fieldItems: fieldItems,
    };
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from '../../components/Breadcrumb';
import Typography from '@mui/material/Typography';
import { CardHeader, CircularProgress } from '@mui/material';
import { UnitSwitch } from '../Attachments/components/UnitSwitch/UnitSwitch';
import { attachmentsService } from '../../services/attachments.service';
import './PartOverview.scss';
import { useQuery } from '@tanstack/react-query';
import { useFields } from './useFields';
import classNames from 'classnames';
export var PartOverview = function () {
    var partNumber = useParams().partNumber; // Mark partNumber as optional
    var _a = useState(null), attachment = _a[0], setAttachment = _a[1];
    var fieldItems = useFields(attachment).fieldItems;
    var isLoading = useQuery({
        enabled: !!partNumber,
        queryKey: [partNumber],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, attachmentsService.getPartInfo(partNumber)];
                    case 1:
                        data = (_a.sent()).data;
                        if (data) {
                            setAttachment(data);
                        }
                        return [2 /*return*/, data];
                }
            });
        }); },
    }).isLoading;
    var classes = classNames('partOverview-card', {
        'is-empty-response': !isLoading && !attachment,
    });
    var breadcrumbs = useMemo(function () { return __spreadArray([
        { label: 'Part search' }
    ], ((attachment === null || attachment === void 0 ? void 0 : attachment.partNumber) ? [{ label: attachment.partNumber }] : []), true); }, [attachment]);
    if (isLoading) {
        return (_jsx("div", { className: "partOverview-loading-container", children: _jsx(CircularProgress, { size: 100 }) }));
    }
    return (_jsxs("div", { className: "partOverview", children: [_jsx(Breadcrumb, { breadcrumbs: breadcrumbs }), _jsx("div", { className: classes, children: attachment ? (_jsxs("div", { children: [_jsxs("div", { className: "partOverview-header", children: [_jsx(CardHeader, { className: "partOverview-header-title", title: attachment.description, subheader: attachment.partNumber }), _jsx(UnitSwitch, {})] }), _jsx("img", { className: "partOverview-image", src: "".concat(process.env.API_URL, "images/parts/").concat(attachment.image), alt: "Image of attachment with respective part number" }), _jsxs("div", { className: "partOverview-content", children: [_jsx(Typography, { variant: "h5", component: "div", children: "Part Overview:" }), fieldItems] })] })) : (_jsx(Typography, { fontSize: 32, children: "No data found" })) })] }));
};

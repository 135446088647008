var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import attachmentsReducer, { initialState as attachmentInitialState, } from './slices/attachments';
import vehicleReducer, { initialState as vehicleInitialState, } from './slices/vehicle';
import lookupReducer, { initialState as lookupInitialState, } from './slices/lookup';
import userReducer, { initialState as userInitialState, } from './slices/userInformation';
import authReducer, { initialState as authInitialState } from './slices/auth';
import { LOG_OUT, RESET_TO_INITIAL } from './globalActions';
var reducers = {
    attachments: attachmentsReducer,
    vehicle: vehicleReducer,
    lookup: lookupReducer,
    user: userReducer,
    auth: authReducer,
};
var combinedReducer = combineReducers(__assign({}, reducers));
var rootReducer = function (state, action) {
    if (action.type === RESET_TO_INITIAL) {
        state = __assign(__assign({}, state), { attachments: attachmentInitialState, vehicle: vehicleInitialState, lookup: lookupInitialState, user: userInitialState, auth: authInitialState });
    }
    if (action.type === LOG_OUT) {
        state = __assign(__assign({}, state), { user: userInitialState, auth: authInitialState });
    }
    return combinedReducer(state, action);
};
export var store = configureStore({
    reducer: rootReducer,
});

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../redux/hooks';
import { MenuList } from '../MenuList';
import { routes } from '../../routes';
import { Accordion } from '../../components/Accordion';
import { AttachmentsList } from '../AttachmentsList';
import { useAnalyticsEventTracker } from '../../hooks/useAnalyticsEventTracker';
import React from 'react';
import PartSearch from '../PartSearch/PartSearch';
import { SidebarContentUser } from './SidebarContentUser';
import { checkUrlSelection } from '../../utils/checkURLSelection';
export var SidebarContent = function (props) {
    var _a = props.setExpandedState, setExpandedState = _a === void 0 ? undefined : _a;
    var eventTracker = useAnalyticsEventTracker('Contact').eventTracker;
    var isSSOEnabled = useAppSelector(function (state) { return state.auth; }).isSSOEnabled;
    var _b = useAppSelector(function (state) { return state.attachments; }), model = _b.model, familyName = _b.familyName;
    var vehicle = useAppSelector(function (state) { return state.vehicle; }).vehicle;
    var onClick = function () {
        if (setExpandedState) {
            setExpandedState('collapsed');
        }
    };
    return (_jsxs("div", { className: "SidebarContent", children: [isSSOEnabled && _jsx(SidebarContentUser, { onClick: onClick }), _jsx("div", { className: "SidebarSection", children: _jsx(MenuList, { onClick: onClick, menuItems: [
                        {
                            href: routes[model ? 'machineSelection' : 'home'],
                            label: 'Machine Selection',
                            config: {
                                isSelected: checkUrlSelection(routes.home),
                            },
                        },
                    ] }) }), (vehicle !== null && vehicle !== void 0 ? vehicle : model) ? (_jsx("div", { className: "SidebarSection", children: _jsx(MenuList, { onClick: onClick, menuItems: [
                        {
                            href: routes.modelSelection(familyName),
                            label: "Machine: ".concat(model),
                        },
                    ] }) })) : undefined, model ? (_jsx(_Fragment, { children: _jsx("div", { className: "SidebarSection", children: _jsx("div", { children: _jsx("ul", { className: "Menu-List", children: _jsx(Accordion, { to: routes.categories(familyName, model), label: "Categories", children: _jsx(AttachmentsList, { onClick: onClick }) }) }) }) }) })) : undefined, _jsx("div", { className: "SidebarSection", children: _jsx(PartSearch, {}) }), _jsx("div", { className: "SidebarSection SidebarSection-Small", children: _jsx("ul", { className: "Menu-List", children: _jsx("li", { className: "Menu-Item Menu-Item-Center", children: _jsx("a", { className: "Menu-Item-Link", href: "mailto:feedback@JCB.com", onClick: function () {
                                eventTracker('Contact us button was clicked');
                                onClick();
                            }, children: "Contact Us" }) }) }) })] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb } from '../../components/Breadcrumb';
import { Banner } from '../../components/Banner';
import './MachineSelection.scss';
import { ChangeModel } from './components/ChangeModel';
export var MachineSelection = function () {
    return (_jsxs("div", { className: "App-content", children: [_jsx(Banner, {}), _jsx(Breadcrumb, { isExpanded: true, breadcrumbs: [
                    {
                        label: 'machine selection',
                    },
                ] }), _jsx("div", { className: "Lookup", children: _jsx(ChangeModel, {}) })] }));
};

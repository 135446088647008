import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import './MenuItem.scss';
import { checkUrlSelection } from '../../utils/checkURLSelection';
export var MenuItem = function (_a) {
    var label = _a.label, _b = _a.href, href = _b === void 0 ? '' : _b, withBorder = _a.withBorder, onClick = _a.onClick, children = _a.children, _c = _a.config, config = _c === void 0 ? {} : _c;
    var location = useLocation();
    var isSelected = useMemo(function () { return checkUrlSelection(href); }, [href, location.pathname]);
    var classes = classNames('Menu-Item', {
        'Menu-Item-Border': withBorder,
        'Menu-Item-Selected': isSelected || config.isSelected,
        'Menu-Item-Flex': !!children,
    });
    return (_jsxs("li", { className: classes, children: [href ? (_jsx(Link, { className: "Menu-Item-Link", to: href, onClick: onClick, children: label })) : (_jsx("button", { className: "Menu-Item-Link", onClick: onClick, children: label })), children] }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Slider from 'react-slick';
import './Banner.scss';
export var Banner = function () {
    var settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: false,
        swipe: false,
        arrows: false,
    };
    return (_jsx("div", { className: "Banner", children: _jsxs(Slider, __assign({}, settings, { children: [_jsx("div", { children: _jsx("img", { src: "".concat(process.env.API_URL, "images/banner_01.jpg"), className: "Banner-Img" }) }), _jsx("div", { children: _jsx("img", { src: "".concat(process.env.API_URL, "images/banner_02.jpg"), className: "Banner-Img" }) }), _jsx("div", { children: _jsx("img", { src: "".concat(process.env.API_URL, "images/banner_03.jpg"), className: "Banner-Img" }) })] })) }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { MenuItem } from '../MenuItem';
import './MenuList.scss';
export var MenuList = function (props) {
    var menuItems = props.menuItems, _a = props.onClick, onClick = _a === void 0 ? undefined : _a, _b = props.className, className = _b === void 0 ? '' : _b;
    var menuElements = menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (item) { return (_jsx(MenuItem, __assign({}, item, { onClick: function () {
            var _a;
            onClick === null || onClick === void 0 ? void 0 : onClick();
            (_a = item.onClick) === null || _a === void 0 ? void 0 : _a.call(item);
        } }), item.href)); });
    var classes = classNames('Menu-List', className);
    return _jsx("ul", { className: classes, children: menuElements });
};

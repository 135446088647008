export var routes = {
    home: '/',
    login: '/login',
    lookup: '/lookup',
    machineSelection: '/machine-selection',
    allPartNumbers: '/part-numbers/all',
    partNumber: function (partNumber) { return "/part-number/".concat(partNumber); },
    modelSelection: function (familyName) { return "/".concat(familyName, "/model-selection"); },
    categories: function (familyName, model) {
        return "/".concat(familyName, "/categories/").concat(encodeURIComponent(model));
    },
    categoriesAttachments: function (familyName, model, category) {
        return "/".concat(familyName, "/categories/").concat(encodeURIComponent(model), "/attachments/").concat(encodeURIComponent(category));
    },
};
